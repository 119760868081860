import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link, graphql } from "gatsby";
import { registerUrl } from '../utils/misc'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Collapsible from 'react-collapsible';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import { DefaultGtag } from '../utils/misc'
import '../styles/PricingPage.css'


const mainStyles = {
    display: "flex",
    flexDirection: "column"
}

function PriceDisplay({ price, period, insteadOf }) {
    const { t } = useTranslation(['pricing']);

    return <div className="service-tier-price">
        <div>
            <span className='price'><b>$</b></span>
            <span className="price"><b>{price}</b></span>
            <span>/{t(`paid-${period}`)}</span>
        </div>
    </div>
}

const PricingPage = () => {
    const [plan, setPlan] = useState("yearly")
    const { t } = useTranslation();
    const [rUrl, setRUrl] = useState('https://admin.takeaseat.io/register?solution=digital-menu')
    const [rProUrl, setRProUrl] = useState('https://admin.takeaseat.io/register?solution=digital-menu&tier=professional')

    useEffect(() => {
        setTimeout(function () {
            // need delay, otherwise not working on firefox...
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }, 100);
        setRUrl(registerUrl())
        setRProUrl(registerUrl("professional", plan))
    }, [])

    useEffect(() => {
        setRUrl(registerUrl('basic', plan))
        setRProUrl(registerUrl("professional", plan))
    }, [plan])

    return (
        <Layout>
            <main style={mainStyles}>
                <div id="pricing" className="page pricing-page">
                    <h1 className="title">{t('main-title')}</h1>
                    <div className="plan-switch">
                        <div
                            className={"monthly" + (plan === "monthly" ? " selected" : "")}
                            onClick={() => setPlan("monthly")}
                        ><span>{t('monthly')}</span></div>
                        <b>/</b>
                        <div
                            className={"yearly" + (plan === "yearly" ? " selected" : "")}
                            onClick={() => setPlan("yearly")}
                        >
                            <span>{t('yearly')}</span>
                            <span>&#160;({t('save')} 50%)</span>
                            {plan !== "yearly" && <span>🌲</span>}
                        </div>
                    </div>
                    <div className="content">
                        <div>
                            <div className={"service-tier starter " + plan}>
                                <div className="title">{t('free.title')}</div>
                                <PriceDisplay tier="free" price={
                                    plan === "monthly" ? 8.90 :
                                        plan === "yearly" ? 4.45 : 4.45
                                } currency="USD" period={plan === "monthly" ? "month" : "year"} insteadOf={plan === "yearly" ? 106 : null} />
                                <div className="service-tier-desc">{t('free.desc')}</div>
                                <div className="highlights">
                                    <div><CheckCircleIcon />{t('free.highlights.0')}</div>
                                    <div><CheckCircleIcon />{t('free.highlights.1')}</div>
                                    <div><CheckCircleIcon />{t('free.highlights.2')}</div>
                                    <div><CheckCircleIcon />{t('free.highlights.3')}</div>
                                    <div><CheckCircleIcon />{t('free.highlights.4')}</div>
                                </div>
                                <div className="pro-button">
                                    <a href={rUrl} rel="noreferrer" className="button">{t('free.get-started')}</a>
                                    <div>
                                        <span><b>{t('premium.trial')}</b> &#160;</span>
                                        <span><b>{t('premium.no-card')}</b></span>
                                    </div>
                                </div>
                                <Collapsible
                                    className="s-only"
                                    triggerWhenOpen={<div className="trigger open"><RemoveIcon /> <b>{t('plan-features')}</b></div>}
                                    trigger={<div className="trigger"><AddIcon /> <b>{t('plan-features')}</b></div>}>
                                    <table className="pricing-table">
                                        <tbody>
                                            <tr><th colSpan="2">{t('free.features.menus.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.0')}</th><td>{t('unlimited')}</td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.1')}</th><td>{t('unlimited')}</td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.2')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.3')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.4')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.5')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.6')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.7')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.8')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.2')}</th><td></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.0')}</th><td></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.3')}</th><td>{t('free.features.translation')}</td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.4')}</th><td></td></tr>
                                            <tr><th colSpan="2">{t('free.features.website.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.website.features.0')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.website.features.1')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.website.features.2')}</th><td>{t('unlimited')}</td></tr>
                                            <tr><th scope="row">{t('free.features.website.features.3')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.website.features.4')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.website.features.5')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.2')}</th><td></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.0')}</th><td></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.1')}</th><td></td></tr>
                                            <tr><th colSpan="2">{t('free.features.reservations.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.reservations.features.0')}</th><td>25</td></tr>
                                            <tr><th scope="row">{t('free.features.reservations.features.1')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.reservations.features.2')}</th><td><CheckIcon /></td></tr>
                                            <tr><th colSpan="2">{t('free.features.contact-tracing.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.contact-tracing.features.0')}</th><td><CheckIcon /></td></tr>
                                            <tr><th colSpan="2">{t('free.features.analytics.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.analytics.features.0')}</th><td><CheckIcon /></td></tr>
                                            <tr><th colSpan="2">{t('free.features.support.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.support.medium')}</th><td><CheckIcon /></td></tr>
                                            {/* <tr><th scope="row">{t('free.features.support.whatsapp')}</th><td><CheckIcon /></td></tr> */}
                                            <tr><th scope="row">{t('free.features.support.priority')}</th><td></td></tr>
                                            {/* <tr><th scope="row">{t('free.features.support.manager')}</th><td></td></tr> */}
                                        </tbody>
                                    </table>
                                </Collapsible>
                            </div>
                        </div>
                        <div>
                            <div className={"service-tier premium " + plan}>
                                <div className="title">{t('premium.title')}</div>
                                <PriceDisplay tier="premium" price={
                                    plan === "monthly" ? 29.90 :
                                        plan === "yearly" ? 14.95 : 14.95
                                } currency="USD" period={plan === "monthly" ? "month" : "year"} />
                                <div className="service-tier-desc">{t('premium.desc')}</div>
                                <div className="highlights">
                                    <div><CheckCircleIcon />{t('premium.highlights.0')}</div>
                                    <div><CheckCircleIcon />{t('premium.highlights.1')}</div>
                                    <div><CheckCircleIcon />{t('premium.highlights.2')}</div>
                                    <div><CheckCircleIcon />{t('premium.highlights.3')}</div>
                                    <div><CheckCircleIcon />{t('premium.highlights.4')}</div>
                                </div>
                                <div className="pro-button">
                                    <a href={rProUrl} rel="noreferrer" className="button">{t('premium.get-started')} 💎</a>
                                    <div>
                                        <span><b>{t('premium.trial')}</b> &#160;</span>
                                        <span><b>{t('premium.no-card')}</b></span>
                                    </div>
                                </div>
                                <Collapsible
                                    className="s-only"
                                    triggerWhenOpen={<div className="trigger open"><RemoveIcon /> <b>{t('plan-features')}</b></div>}
                                    trigger={<div className="trigger"><AddIcon /> <b>{t('plan-features')}</b></div>}>
                                    <table className="pricing-table">
                                        <tbody>
                                            <tr><th colSpan="2">{t('free.features.menus.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.0')}</th><td>{t('unlimited')}</td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.1')}</th><td>{t('unlimited')}<br /> <i>{t('hq')}</i></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.2')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.3')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.4')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.5')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.6')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.7')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.menus.features.8')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.2')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.0')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.3')}</th><td>{t('premium.features.translation')}</td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.4')}</th><td><CheckIcon /></td></tr>
                                            <tr><th colSpan="2">{t('free.features.website.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.website.features.0')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.website.features.1')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.website.features.2')}</th><td>{t('unlimited')}</td></tr>
                                            <tr><th scope="row">{t('free.features.website.features.3')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.website.features.4')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.website.features.5')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.2')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.0')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('premium.features.website.features.1')}</th><td><CheckIcon /></td></tr>
                                            <tr><th colSpan="2">{t('free.features.reservations.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.reservations.features.0')}</th><td>{t('unlimited')}</td></tr>
                                            <tr><th scope="row">{t('free.features.reservations.features.1')}</th><td><CheckIcon /></td></tr>
                                            <tr><th scope="row">{t('free.features.reservations.features.2')}</th><td><CheckIcon /></td></tr>
                                            <tr><th colSpan="2">{t('free.features.contact-tracing.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.contact-tracing.features.0')}</th><td><CheckIcon /></td></tr>
                                            <tr><th colSpan="2">{t('free.features.analytics.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.analytics.features.0')}</th><td><CheckIcon /></td></tr>
                                            <tr><th colSpan="2">{t('free.features.support.title')}</th></tr>
                                            <tr><th scope="row">{t('free.features.support.medium')}</th><td><CheckIcon /></td></tr>
                                            {/* <tr><th scope="row">{t('free.features.support.whatsapp')}</th><td><CheckIcon /></td></tr> */}
                                            <tr><th scope="row">{t('free.features.support.priority')}</th><td><CheckIcon /></td></tr>
                                            {/* <tr><th scope="row">{t('free.features.support.manager')}</th><td><CheckIcon /></td></tr> */}
                                        </tbody>
                                    </table>
                                </Collapsible>
                            </div>
                        </div>
                        <div>
                            <div className={"service-tier enterprise"}>
                                <div className="title">{t('enterprise.title')}</div>
                                <div className="service-tier-price"><div>
                                    <span className="price"><b>{t('enterprise.pricing')}</b></span>
                                </div></div>
                                <div className="service-tier-desc">{t('enterprise.desc')}</div>
                                <div className="highlights">
                                    <div><CheckCircleIcon />{t('enterprise.highlights.0')}</div>
                                    <div><CheckCircleIcon />{t('enterprise.highlights.1')}</div>
                                    <div><CheckCircleIcon />{t('enterprise.highlights.2')}</div>
                                </div>
                                <div style={{ marginTop: "0.9rem" }}>{t('enterprise.desc-2')}</div>
                                <div className="pro-button">
                                    <Link to='/contact/' className="button">{t('navigation.contact-us')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="recent-launch">
                        <div dangerouslySetInnerHTML={{ __html: t('discount') }}></div>
                    </div>
                    <div className="md-plus-only">
                        <table className="pricing-table">
                            <thead>
                                <tr>
                                    <th style={{ border: "0px" }}></th>
                                    <th>
                                        <div className="title">{t('free.title')}</div>
                                    </th>
                                    <th>
                                        <div className="title">{t('premium.title')}</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><th colSpan="3">{t('free.features.menus.title')}</th></tr>
                                <tr><th scope="row">{t('free.features.menus.features.0')}</th><td>{t('unlimited')}</td><td>{t('unlimited')}</td></tr>
                                <tr><th scope="row">{t('free.features.menus.features.1')}</th><td>{t('unlimited')}</td><td>{t('unlimited')}<br /> <i>{t('hq')}</i></td></tr>
                                <tr><th scope="row">{t('free.features.menus.features.2')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('free.features.menus.features.3')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('free.features.menus.features.4')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('free.features.menus.features.5')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('free.features.menus.features.6')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('free.features.menus.features.7')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('free.features.menus.features.8')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('premium.features.website.features.2')}</th><td></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('premium.features.website.features.0')}</th><td></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('premium.features.website.features.3')}</th><td>{t('free.features.translation')}</td><td>{t('premium.features.translation')}</td></tr>
                                <tr><th scope="row">{t('premium.features.website.features.4')}</th><td></td><td><CheckIcon /></td></tr>
                                <tr><th colSpan="3">{t('free.features.website.title')}</th></tr>
                                <tr><th scope="row">{t('free.features.website.features.0')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('free.features.website.features.1')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('free.features.website.features.2')}</th><td>{t('unlimited')}</td><td>{t('unlimited')}</td></tr>
                                <tr><th scope="row">{t('free.features.website.features.3')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('free.features.website.features.4')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('free.features.website.features.5')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('premium.features.website.features.2')}</th><td></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('premium.features.website.features.0')}</th><td></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('premium.features.website.features.1')}</th><td></td><td><CheckIcon /></td></tr>
                                <tr><th colSpan="3">{t('free.features.reservations.title')}</th></tr>
                                <tr><th scope="row">{t('free.features.reservations.features.0')}</th><td>25</td><td>{t('unlimited')}</td></tr>
                                <tr><th scope="row">{t('free.features.reservations.features.1')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th scope="row">{t('free.features.reservations.features.2')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th colSpan="3">{t('free.features.contact-tracing.title')}</th></tr>
                                <tr><th scope="row">{t('free.features.contact-tracing.features.0')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th colSpan="3">{t('free.features.analytics.title')}</th></tr>
                                <tr><th scope="row">{t('free.features.analytics.features.0')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                <tr><th colSpan="3">{t('free.features.support.title')}</th></tr>
                                <tr><th scope="row">{t('free.features.support.medium')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                                {/* <tr><th scope="row">{t('free.features.support.whatsapp')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr> */}
                                <tr><th scope="row">{t('free.features.support.priority')}</th><td></td><td><CheckIcon /></td></tr>
                                {/* <tr><th scope="row">{t('free.features.support.manager')}</th><td></td><td><CheckIcon /></td></tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default PricingPage

export const Head = () => <>
    <DefaultGtag />
    <title>Take a Seat Pricing page</title>
    {/* <link rel="canonical" href="https://takeaseat.io/pricing/" /> */}
    <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/pricing/" />
    <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/pricing/" />
    <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/pricing/" />
    <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/pricing/" />
    <meta name="description" content="Pricing page of Take a Seat services." />
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","pricing","navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;